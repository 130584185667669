import { FC, memo } from 'react';
import { Typography } from '@sravni/react-design-system';
import { Container } from '../../../landing.common';
import styles from './styles.module.scss';

const { Heading, Text } = Typography;

export const Hero: FC = memo(function HeroComponent() {
    const year = new Date().getFullYear();
    return (
        <Container className={styles.hero}>
            <div className={styles.hero__text}>
                <Heading level={1} className={styles.header_text}>
                    Подбор кредита
                </Heading>
                <Text size={14} className={styles.description_text}>
                    Сравните условия в разных банках и решите, где лучше&nbsp;взять&nbsp;кредит&nbsp;онлайн в {year}{' '}
                    году.
                    <br /> Заполните анкету, чтобы узнать, в каких банках одобрят кредит
                </Text>
            </div>
            <div className={styles.hero__image}>
                <img src={require('../../assests/hero/hero.png')} alt="Credit img" />
            </div>
        </Container>
    );
});
